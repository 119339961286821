<template>
  <div>
    <div class="flix-flex flix-flex-list" v-if="!$store.state.save.privateUser">
      <div class="item preview flix-form-group">
        <h3>{{ $t("register.preview") }}</h3>
        <flix-calendar id="1" :data="preview" callback="calendarSettings" />
      </div>

      <div
        class="item register flix-flex flix-flex-center flix-gap-10 flix-mt-50"
        style="flex-direction: column;"
      >
        <form>
          <saveBtn :label="$t('private.save.button')" :callback="setSave" />
        </form>
        <div>{{ $t("private.save.info", { email: data.email }) }}</div>
      </div>
    </div>
    <div
      class="flix-flex flix-flex-center flix-mt-50"
      style="text-align: center"
      v-else
    >
      <success />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    success() {
      return import("@/components/business/preview/success");
    }
  },
  props: {},
  data() {
    return {
      preview: btoa(
        JSON.stringify({
          data: this.$store.state.privateEvent.unsaved
        })
      ),
      data: JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved))
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setSave() {
      if (typeof this.data.ID === "undefined") {
        this.data.ID =
          new Date().getTime() + "" + Math.floor(Math.random(0, 1) * 10000);
      }

      this.$flix_post({
        url: "assistent/privates/save",
        data: { data: this.data },
        callback: function(ret) {
          if (ret.data[0]) {
            this.setSendUserEmail();
          }
        }.bind(this)
      });
    },
    createPrivatePassword(id) {
      id = btoa(id);
      var l = id.length;
      return id[l - 3] + "" + id[l - 2] + "" + id[0] + "" + id[3] + "" + id[5];
    },
    setSendUserEmail() {
      var url = this.$flix_url + "/pe/" + this.data.ID;
      var editurl = this.$flix_url + "/pe/" + this.data.ID + "/edit.html";
      var pdf =
        this.$flix_url + "/pe/pdf/" + btoa(btoa(this.data.ID)) + "/info.pdf";

      var txt = "";
      txt += this.$t("private.email.text");

      txt = txt.split("{name}").join('"' + this.data.title + '"');
      txt = txt
        .split("{password}")
        .join(this.createPrivatePassword(this.data.ID));
      txt = txt.split("{number}").join(this.data.ID);
      txt = txt
        .split("{bookingLink}")
        .join('<a href="' + url + '" target="_blank">' + url + "</a>");
      txt = txt
        .split("{editLink}")
        .join('<a href="' + editurl + '" target="_blank">' + editurl + "</a>");
      txt = txt
        .split("{PDFLink}")
        .join('<a href="' + pdf + '" target="_blank">' + pdf + "</a>");

      var data = {
        ID: this.data.ID,
        text: txt,
        title: this.data.title,
        to: this.data.email
      };

      this.$flix_post({
        url: "assistent/privates/sharingmail",
        data: data,
        callback: function() {
          this.$store.commit("save/setPrivateUser");
        }.bind(this)
      });
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.container
  display: flex
  margin-left: -10px
  margin-right: -10px
  h3
    margin-top: 0
    text-align: center
  .item
    flex: 1
  .item.register
    background-color: $light-grey
    border-radius: 3px
    padding: 30px
    box-sizing: border-box
  .item.preview
    flex: 1

@media(max-width: 500px)
  .container
    flex-direction: column-reverse
    margin: 0
    .item.register, .item.preview
      width: 100%
      flex: 1
      padding: 5px
</style>
